import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

import Pagination from '@/views/components/Pagination'
import { instance, http_file } from '@/utils/http.js'
import basicUrl from '@/utils/basicUrl'
// 引入富文本编辑器
import VueUeditorWrap from 'vue-ueditor-wrap'
import getFile from './utils/file'
import Group from '@/components/menuGroup.vue'
import './utils/directives'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import * as echarts from 'echarts';
// 引入公共样式
import '@/statics/index.scss'

import Table from '@/components/table/tables.vue'
import Shuttle from '@/components/table/index.vue'

// import setRoutes from '@/utils/setRoutes.js'
import '@/utils/setRoutes.js'

import getMd5Form from '@/utils/getMd5Form.js'
Vue.prototype.$getMd5Form = getMd5Form

Vue.prototype.$bus = new Vue();

import { rands } from '@/utils/rand.js'
Vue.prototype.$rands = rands


// setRoutes()


Vue.prototype.$echarts = echarts


Vue.use(preview)
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
// 0 未关联  -1 取消申请 1 制作中  2 制作完成，未确定 3 已生成 4 申请中"
Vue.prototype.$sheetStatus = {
  0: "未关联",
  1: "制作中",
  '-1': "取消申请",
  2: "制作完成，未确定",
  3: "已生成",
  4: "申请中"
}

Vue.prototype.$forbid = function () {
  //禁止软键盘弹出
  document.activeElement.blur();
}

//选择文件功能 
Vue.prototype.$get_file = getFile
// bsaic请求基底
Vue.prototype.$basicUrl = basicUrl
// 挂载请求
Vue.prototype.$http = instance
// 上传文件功能
Vue.prototype.$http_file = http_file
// 富文本编辑器
Vue.component('VueUeditorWrap', VueUeditorWrap)
// 分页通用组件
Vue.component('pagination', Pagination)
// 表格通用组件
Vue.component('Table', Table)
// 表格通用组件
Vue.component('Shuttle', Shuttle)

Vue.component('menu-group', Group)
// 自定义指令
Vue.directive('permission', {
  inserted: function (el, binding) {
    // userinfo用来存储用户信息，路由权限控制，router里面有个roles  
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let roles = binding.value
    if (roles.indexOf(userInfo.role) === -1) {
      el.style.display = 'none'
    }
  },
})


// 正则拼接富文本的url前缀
function replaceAll (value) {
  if (!value) {
    return
  }
  var msg = 'src="/'
  return value.replace(new RegExp(msg, 'g'), `src="https://sunraytiku.oss-cn-beijing.aliyuncs.com/`)
}
Vue.prototype.$replaceAll = replaceAll

// 统一配置ueditor配置项目
var ueditorConfig = {
  UEDITOR_HOME_URL: '/ueditor/',  // 需要令此处的URL对等于 ueditor.config.js 中的配置。
  // 编辑器不自动被内容撑高
  autoHeightEnabled: true,
  // 初始容器高度
  initialFrameHeight: 320,
  // 初始容器宽度
  initialFrameWidth: '100%',
  autoFloatEnabled: false,
  // 上传文件接口
  // serverUrl: 'http://35.201.165.105:8000/controller.php',
  serverUrl: `${basicUrl}/ueditor/controller.php`,
}
Vue.prototype.$config = ueditorConfig

// 拼接图片服务器地址
function addHeader (value) {
  return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
}
Vue.prototype.$addHeader = addHeader

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
