<template>
  <div>
    <menu-group v-if="item.child"
                :group="item" />
    <div v-else>
      <el-menu-item v-if="!item.is_header"
                    @click.native="toPath(item)"
                    :index="item.menu_path">
        <img v-if="item.icon"
             :src="require('../assets/icons/' + item.icon + '.png')"
             class="left_menu_icon"
             alt="">
        <span v-if="!this.$parent.$parent.isCollapse">{{item.menu_name}}
        </span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: ['item'],
  data () {
    return {}
  },
  methods: {
    toPath (item) {
      if (this.$route.path == item.menu_path) {
        return
      }
      this.$router.push({ path: item.menu_path })
    }
  },
}
</script>

<style lang="scss" scoped>
/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

// 子菜单项鼠标悬浮样式
::v-deep .el-menu-item {
  transition: all 0.3s ease;
  font-size: 14px;
  // color: white;
  &:hover {
    background: white !important;
    color: #409eff !important;
  }
}

::v-deep .el-submenu__title:hover {
  background: #409eff !important;
  color: white;
}
::v-deep .el-submenu__title {
  background: #8bc5fd;
}
// 子菜单项激活样式
::v-deep .el-menu-item.is-active {
  font-size: 16px;
  font-weight: bold;
  background: white !important;
  color: #409eff !important;
  img {
    filter: invert(0.4) sepia(0.9) hue-rotate(158deg);
    mix-blend-mode: darken;
  }
}
.left_menu_icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
</style>