import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'
import { Message } from 'element-ui'
import getMd5Form from './getMd5Form.js'


export const refresh_instance = axios.create({
  baseURL: basicUrl,
  timeout: 360000000,
});

refresh_instance.interceptors.request.use(async config => {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    config.headers['Authorization'] = `Bearer ${userInfo.token}`
  }
  config.data.sign = getMd5Form(config.data)
  return config
})


refresh_instance.interceptors.response.use(async config => {
  config = config.data
  return await new Promise((resolve, reject) => {
    if (config.status == 200) {
      resolve(config)
    } else {
      // 当状态码不为200的时候，直接拦截
      if (config.msg == 'error') {
        Message.error(config.data.msg)
      } else {
        Message.error(config.msg)
      }
      if (config.status == 400) {
        window.localStorage.removeItem('userInfo')
        router.push('/login')
      }
      reject(config)
    }
  })
})