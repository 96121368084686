<template>
  <div>
    <el-submenu :index="group.menu_name"
                v-if="!isHidden">
      <template slot="title">
        <img :src="require('../assets/icons/' + group.icon + '.png')"
             class="left_menu_icon"
             v-if="group.icon"
             alt="">
        <span>{{group.menu_name}}</span>
      </template>
      <el-menu-item-group>
        <menu-item v-for="item in group.child"
                   :item="item"
                   :key="item.menu_path" />
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item v-else
                  @click.native="toPath(group)"
                  :index="group.menu_path">
      <img :src="require('../assets/icons/' + group.icon + '.png')"
           v-if="group.icon"
           class="left_menu_icon"
           alt="">
      <span v-if="!this.$parent.$parent.isCollapse">{{group.menu_name}}
      </span>
    </el-menu-item>
  </div>
</template>

<script>
import MenuItem from './menuItem.vue'

export default {
  name: 'MenuGroup',
  components: {
    MenuItem,
  },
  props: ['group'],
  data () {
    return {
      isHidden: true
    }
  },
  mounted () {
    if (this.group.child && this.group.child.length) {
      this.isHidden = this.group.child.every(item => {
        return item.is_header == 1
      })
    }

  },
  methods: {
    toPath (item) {
      if (this.$route.path == item.menu_path) {
        return
      }
      this.$router.push({ path: item.menu_path })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item-group__title {
  display: none;
}
::v-deep .el-submenu__title:hover {
  background: #409eff !important;
  color: white;
}
// 子菜单项激活样式
::v-deep .el-menu-item.is-active {
  font-size: 16px;
  font-weight: bold;
  background: white !important;
  color: #409eff !important;
  img {
    filter: invert(0.4) sepia(0.9) hue-rotate(158deg);
    mix-blend-mode: darken;
  }
}

// 子菜单项鼠标悬浮样式
::v-deep .el-menu-item {
  transition: all 0.3s ease;
  font-size: 14px;
  // color: white;
  &:hover {
    background: white !important;
    color: #409eff !important;
  }
}

.left_menu_icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
</style>
