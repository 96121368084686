<template>
  <div id="app">
    <div id="math-type-hidden">math type font loading...+-</div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  mounted () {
    var that = this
    document.addEventListener('plusready', function (a) { //等待plus ready后再调用5+ API：
      plus.key.addEventListener('backbutton', function () { //监听返回键
        plus.runtime.quit();
      }, false);
    });

  }

}
</script>

<style lang="scss" >
#app {
  width: 100%;
  height: 100vh;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd {
  margin: 0;
  color: black;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  border: none;
  vertical-align: top;
}
a {
  text-decoration: none;
}
.top-floor {
  z-index: 9999 !important;
}
.text_btn_primary {
  cursor: pointer;
  color: #409eff;
  padding: 10px;
  display: inline-block;
}
.text_btn_primary1 {
  cursor: pointer;
  color: #409eff;
  padding: 5px 16px;
  display: inline-block;
  border: 1px solid #409eff;
  border-radius: 23px;
  margin-right: 16px;
  margin-top: 5px;
  &:hover {
    background: #f9fdff;
  }
}
.text_btn_danger {
  cursor: pointer;
  color: #ff0000;
  padding: 10px;
  display: inline-block;
}

.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 5px 18px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #20c192;
  background: #cff7ea;
  padding: 0 15px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  border-radius: 5px;
}
.text_link {
  cursor: pointer;
  color: #3F9EFF;
  display: inline;
  margin-right: 10px;
}

.notpass {
  color: #fe765f;
  background: #fde9e9;
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  border-radius: 5px;
}
.unload {
  // width: 88px;
  color: #ef5350;
  display: inline-block;
  padding: 2px 8px;
  height: 24px;
  font-size: 12px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  line-height: 18px;
  border: 1px solid #ef5350;
}
.wpg {
  font-size: 12px;
  padding: 2px 8px;
  color: #ffa726;
  display: inline-block;
  height: 24px;
  line-height: 18px;
  width: 88px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #ffa726;
}
.ypg {
  font-size: 12px;
  padding: 2px 8px;
  line-height: 18px;
  display: inline-block;
  color: #66bb6a;
  height: 24px;
  // width: 88px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #66bb6a;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.operyion_btn_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.el-tooltip__popper {
  max-width: 800px;
}
::v-deep .el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: 25px;
}
.opertion_font {
  font-weight: bold;
}

.shenlue {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.oper_btn {
  cursor: pointer;
}
#math-type-hidden {
  font-family: Symbol, MT Extra, serif;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}
</style>
