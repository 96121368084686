import CryptoJS from 'crypto-js';
import api_key from './api_key.js'

const getMd5Form = function (form, url) {
  form = JSON.parse(JSON.stringify(form))
  let str = ''
  form.api_key = api_key;
  if (url == '/api/rpc/gd/sso_url' || url == '/api/rpc/gd/review_url') {
    form.api_key = 'A719n7UkTikur*6_@MhJ'
  }
  let keys = Object.keys(form);
  keys.sort().forEach(item => {
    if (form[item] === null) {
      form[item] = ''
    }
    if (Array.isArray(form[item])) {
      str += JSON.stringify(form[item])
    } else {
      str += typeof form[item] != 'undefined' ? form[item] : '';
    }
  });
  return CryptoJS.MD5(str).toString();
}

export default getMd5Form
