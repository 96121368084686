import store from "../store";
store.dispatch('menu/getAuthMenu')

// import { instance, http_file } from './http.js'
// import router from '@/router'

// function setRoutes () {
//   let routes = router.options.routes
//   let indexPage = routes.find(item => item.path == '/index')
//   let userInfo = window.localStorage.getItem('userInfo')
//   if (!indexPage.length && userInfo) {
//     instance({
//       url: '/api/v1/power/admin_auth',
//       method: 'POST'
//     }).then(res => {
//       let routerList = res.data.list
//       window.localStorage.setItem('menuList', JSON.stringify(routerList))

//       let newRouterList = []

//       let list = flatList(routerList)
//       list.forEach(item => {
//         let obj = {
//           path: item.menu_path,
//           meta: {
//             title: item.menu_name,
//           },
//           component: () => import(`@/views/${item.unique_auth}`)
//         }
//         newRouterList.push(obj)
//       })
//       let notFound = {
//         path: '*',
//         meta: {
//           title: '404',
//           icon: '',
//           roles: ['admin']
//         },
//         name: "notfound",
//         component: () => import('@/views/404.vue')
//       }
//       newRouterList.push(notFound)
//       indexPage.children = [...newRouterList]
//       router.addRoutes(routes)
//     })
//   }
// }
// function flatList (list) {
//   let allList = []
//   for (let i = 0, len = list.length; i < len; i++) {
//     allList.push(list[i])
//     if (list[i].child && list[i].child.length > 0) {
//       allList = allList.concat(flatList(list[i].child))
//     }
//   }
//   return allList
// }

// export default setRoutes
