import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router'
import menu from './modules/menu'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 表格高度
    tableHeight: 0,
    tags: [],
    // 渲染菜单使用
    menuList: [],
    // 动态注册路由使用
    routerList: [],
    userInfo: {},
    // 菜单颜色,
    theme: {
      menuColor: ' #66B1FF',
      menuTextColor: '#DADCDE',
      menuActiveColor: 'white',
      activeColor: 'white',
      navBarColor: '#FFFFFF'
    },
    isCollapse: false,
    // 面包屑导航
    navigation: []
  },
  getters: {
  },
  mutations: {
    SET_TABLE_HEIGHT (state, height) {
      state.tableHeight = height
    },
    SET_NAVIGATION (state, to) {
      let obj = {
        path: to.path,
        meta: to.meta,
      }
      let historyList = getParentList(obj)
      state.navigation = historyList
    },
    DEL_NAVIGATION (state, from) {
      if (state.navigation.findIndex(item => item.path == from.path) != -1) {
        state.navigation.splice(state.navigation.findIndex(item => item.path == from.path), 1)
      }
    },
    CLEAR_NAVIGATION (state) {
      state.navigation = []
    },
    CHECK_COLLAPSE (state) {
      state.isCollapse = !state.isCollapse
    }
  },
  actions: {
  },
  modules: {
    menu
  }
})


function getParentList (to) {
  let flatList = toObject(menu.state.flat_menu_List)

  let list = searchParent(to, flatList)
  return list;
}

function searchParent (to, flatList) {
  let arr = [to]
  if (!to.meta.pid) {
    return arr
  } else {
    return [...searchParent(flatList[to.meta.pid], flatList), ...arr]
  }
}

function toObject (list) {
  let obj = {}
  list.forEach(item => {
    obj[item.id] = {
      path: item.menu_path,
      meta: {
        pid: item.pid,
        id: item.id,
        title: item.menu_name,
        unique_auth: item.unique_auth
      }
    }
  });
  return obj
}


window.addEventListener('load', () => {
  store.commit('SET_TABLE_HEIGHT', document.body.clientHeight - 290)
})
window.addEventListener('resize', () => {
  store.commit('SET_TABLE_HEIGHT', document.body.clientHeight - 290)
})
export default store

