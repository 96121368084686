import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { loadings, requestSign } from '@/utils/http.js'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  // index页面的位置不能变，在home页面直接拿的index的children的数据来渲染菜单栏的
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Home.vue'),
    redirect: '/homepage',
    children: []
  },
  // 激活教师卡
  {
    path: '/teacherCard',
    name: 'teacherCard',
    component: () => import('@/views/teacherCard'),
  },
]
const router = new VueRouter({
  routes
})




const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}
//路由白名单 ,配置的路由直接放行
const dischargedList = ['/login', '/', '/index', '/404', '/teacherCard']
// 判断是否登录
router.beforeEach((to, from, next) => {
  // console.log('router', router)
  requestSign.setNumber(0)
  // 跳转时，关闭之前发出的请求所产生的loading 所以后续页面的请求都尽量在mounted里面发出
  if (loadings) {
    loadings.close()
  }
  let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  // 显示进度条
  NProgress.start()
  if (dischargedList.indexOf(to.path) !== -1) {
    next()
  } else {
    // 用户token不存在的时候就判定未登录
    if (!userInfo) {
      next('/login')
    }
  }

  // 查看是否存在路由白名单里面
  if (dischargedList.indexOf(to.path) == -1) {
    store.commit('SET_NAVIGATION', to)
  }

  next()
})

router.afterEach(() => {
  if (document.getElementsByClassName('view_warp').length) {
    document.getElementsByClassName('view_warp')[0].style.background = ''
    document.getElementsByClassName('view_warp')[0].style.padding = '15px'
  }
  // 完成进度条
  NProgress.done()
})

export default router
