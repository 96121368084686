import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'
import Vue from 'vue'
import { Message, Loading } from 'element-ui'
import getMd5Form from './getMd5Form.js'
import { refresh_token } from '@/api/refresh_token.js'



export const instance = axios.create({
  baseURL: basicUrl,
  timeout: 36000000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 36000000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})
// 不显示loading的接口(答题卡上传，进度条接口)
var noLoadingApi = [
  '/api/v1/test/upload_sheet',
  '/api/v1/test/again_audit_sheet',
  '/api/v1/test/upload_sheet_schedule',
  '/api/v1/papers/no_nstandard_upload',
  '/api/v1/papers/user_paper_upload',
  '/api/v1/papers/task_status',
  '/api/v1/papers/det_batch_taskid',
]
// 设置放行路由，放行错误控制
var role = [
  '/api/v1/student/export_stat',
  '/api/v1/combination/stats_export',
  '/api/v1/combination/paper_print',
  '/api/v1/combination/paper_print_word'
]
function getTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    var time = Date.parse(new Date()).toString(); //精确到毫秒
    time = time.substr(0, 10); //精确到秒
  }
}


export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

export var loadings
function openLoading () {
  // 开启全局loading 
  if (!requestSign.number) {
    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('#view_warp'),
      text: '正在加载中!',
      spinner: 'el-icon-loading'
    });
  }
  requestSign.number += 1
}

function closeLoading () {
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0) {
      requestSign.number = 0
    }
    if (requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}

function addInterceptors (face) {
  face.interceptors.request.use(async config => {
    if (config.url != '/api/v1/refresh_token' || config.url != '/api/v1/login') {
      await getTime()
    }
    if (window.localStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      config.headers['Authorization'] = `Bearer ${userInfo.token}`
    }

    if (!config.headers.isRefresh) {
      delete config.headers.isRefresh
      if (config.method == 'get') {
        if (!config.params) {
          config.params = {}
        }
        if (config.url == '/api/rpc/gd/sso_url' || config.url == '/api/rpc/gd/review_url') {

          config.params.sign = getMd5Form(config.params,config.url)

        } else {
          config.params.sign = getMd5Form(config.params)
        }

      }
      else {
        if (!config.data) {
          config.data = {}
        }
        config.data.sign = getMd5Form(config.data)
      }
    }
    delete config.headers.isRefresh
    // openLoading()
    if (noLoadingApi.indexOf(config.url) == -1) {
      openLoading()

    }
    return config
  })
  face.interceptors.response.use(async config => {
    closeLoading()

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }
    var response = config
    config = config.data
    // console.log(config)
    return await new Promise(async (resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.status == 400) {
          if (config.msg == 'error') {
            Message.error(config.data.msg)
          } else {
            Message.error(config.msg)
          }
          reject(config)
        }
      }
      // console.log(response.config.url, lock)
      if (config.status == 410003 || config.status == 410004) {
        Message.error(config.msg)
        reject(config)
      }
      if (config.status == 410000) {

        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        requestSign.number == 0
        let form = {
          refresh_token: userInfo.refresh_token
        }
        const { data } = await refresh_token(form)
        window.localStorage.setItem('userInfo', JSON.stringify(data))

        var token = data.token
        response.config.headers.Authorization = `Bearer ${token}`
        response.config.headers.isRefresh = '1'
        config = await instance(response.config)
      }
      resolve(config)
    })
  })
}

addInterceptors(instance)
addInterceptors(http_file)
