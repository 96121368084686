import { getAuth } from '@/api/auth.js'
import router from '@/router'
const state = {
  menuList: [],
  flat_menu_List: []
}

const mutations = {
  set_menu_list (state, payload) {
    state.menuList = payload
  },
  set_flat_menu_list (state, payload) {
    state.flat_menu_List = payload
  }
}

const actions = {
  async getAuthMenu (context) {
    const { data } = await getAuth()
    context.commit('set_menu_list', data.list)
    // 浏览器存储起来，因为使用vuex，页面刷新的时候，会有时间稍长一点的白屏，部署在nginx下的时候不会有白屏
    // window.sessionStorage.setItem('menuList', JSON.stringify(data.list))
    let routerList = data.list
    let routes = router.options.routes
    let indexPage = routes.find(item => item.path == '/index')
    let newRouterList = []
    let list = flatList(routerList)
    context.commit('set_flat_menu_list', list)
    list.forEach(item => {
      let obj = {
        path: item.menu_path,
        meta: {
          title: item.menu_name,
          id: item.id,
          pid: item.pid,
          unique_auth: item.unique_auth
        },
        component: () => import(`@/views/${item.unique_auth}`)
      }
      newRouterList.push(obj)
    })
    // newRouterList.push({
    //   path:'/welcome',
    //   meta:{
    //     title:'欢迎页',
    //     id:9999,
    //     pid:0
    //   },
    //   component:()=>import("@/views/homepage/components/welcome/index.vue")
    // })
    let notFound = {
      path: '*',
      meta: {
        title: '404',
        icon: '',
        roles: ['admin']
      },
      name: "notfound",
      component: () => import('@/views/404.vue')
    }
    newRouterList.push(notFound)
    indexPage.children = [...newRouterList]
    router.addRoutes(routes)
  }
}

function flatList (list) {
  let allList = []
  for (let i = 0, len = list.length; i < len; i++) {
    allList.push(list[i])
    if (list[i].child && list[i].child.length > 0) {
      allList = allList.concat(flatList(list[i].child))
    }
  }
  return allList
}

export default {
  state, mutations, actions,
  namespaced: true
}